import UserImageProfileStyle from './UserImageProfile.module.css';
import React, {useEffect, useState} from "react";
import PopupDownAccountStyle from "./PopupDown/PopupDownAccount.module.css";

const UserImageProfile = (props) => {
  const {userID, size, name= null, defaultInitial = 'W4S'} = props;

  const [initial, setInitial] = useState(defaultInitial);
  const [loadedImage, setLoadedImage] = useState(false);
  const link = `https://cdn.codnum.fr/watch4safe/assets/users/${userID}.jpg`;

  useEffect(() => {
    const img = new Image();
    const link = `https://cdn.codnum.fr/watch4safe/assets/users/${userID}.jpg`;
    img.src = link;
    console.log(link);
    console.log(name);

    img.onload = () => {
      setLoadedImage(true);
    };
  }, [userID]);

  useEffect(() => {
    let theInitial = '';

    if (name) {
      theInitial += name.split(' ').map(part => part.charAt(0)).join('');
    }

    if (theInitial === '') {
      theInitial = defaultInitial;
    }

    setInitial(theInitial.toUpperCase());
  }, [name]);

  return (
    <div className={UserImageProfileStyle.element} style={{width: size, height: size}}>
      {!loadedImage ?
        <>
          <span className={UserImageProfileStyle.name}>{initial}</span>
        </>
        :
        <>
          <img
            className={UserImageProfileStyle.image}
            src={link}
            alt={''}
            height={size}
            width={size}
          />
        </>
      }
    </div>
  );
}

export default UserImageProfile;

import BaseStyle from '../assets/css/Dashboard.module.css';

import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import api from "../services/api";
import MediaPlayer from "../components/MediaPlayer";
import {useTheme} from "../provider/themeProvider";
import ButtonActionElement from "../components/ButtonAction.component";
import PopupDownYesNo from "../components/PopupDown/PopupDownYesNo";
import {usePopup} from "../services/PopupContext";
import PopupDownShare from "../components/PopupDown/PopupDownShare";
import PopupDownOldShare from "../components/PopupDown/PopupDownOldShare";

const Record = () => {
  const [record, setRecord] = useState(null);
  const [images, setImages] = useState([]);
  const { id } = useParams();
  const { darkMode } = useTheme();
  const { showPopup } = usePopup();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSequence = async () => {
      try {
        const response = await api.get(`/records/${id}`);

        if (!response.data) {
          throw new Error('Sequence not found');
        }
        console.log(response.data);
        setRecord(response.data.record);
        setImages(response.data.files);
      } catch (error) {
        setRecord(null);
      }
    };

    fetchSequence();
  }, []);

  useEffect(() => {
    console.log('record mis a jour');
    console.log(record);
  }, [record])

  const handleClickAction = async (action, data = null) => {
    try {
      const response = await api.put(`/client/record/${record.id}`, {action, data});
      if (response.data.success) {
        if (response.data.data === null) {
          navigate('/notifications', {replace: true})
          return;
        }
        record.sharing = response.data.data.sharing;
        record.sharing_id = response.data.data.sharing_id;
        if (response.data.link !== null) {
          return response.data.link;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleDelete = () => {
    showPopup(PopupDownYesNo, { request: 'Voulez-vous supprimer cette séquence ?', titleYes: 'Supprimer', titleNo: 'Annuler', callbackYes: () => handleClickAction('delete'), callbackNo: () => {} });
  };

  const handleShare = () => {
    if (record.sharing) {
      shareOldRecord();
    } else {
      shareRecord();
    }
  };

  const shareRecord = () => {
    showPopup(PopupDownShare, {
      title: 'Partager cette restitution',
      callbackShare: (time, password) => {
        handleClickAction('createShare', {time, password}).then((shareLink) => {
          if (shareLink) {
            shareOldRecord();
          }
        });
      }
    });
  }

  const shareOldRecord = () => {
    showPopup(PopupDownOldShare, {
      title: 'Votre partage',
      linkCopy: `${window.location.origin}/sharing/sequences/record/${record.sharing_id}`,
      oldShare: record.sharing,
      callbackDelete: () => {
        handleClickAction('deleteShare');
      },
      callbackDeleteAndNew: (time, password) => {
        handleClickAction('deleteShare');
        shareRecord();
      }
    });
  }

  return (
    <>
      <div className={BaseStyle.header}>
        <div className={BaseStyle.left}>
          <div className={BaseStyle.iconContainer}>
            <img src={`https://cdn.codnum.fr/watch4safe/assets/images/folder_${darkMode ? 'black' : 'white'}.svg`} alt="" height="20" width="20"/>
          </div>
          <div className={BaseStyle.description}>
            <span className={BaseStyle.title}>Séquence</span>
          </div>
        </div>
        <div className={BaseStyle.right}>
          <ButtonActionElement callback={handleDelete} size={20} icon={'trash'}/>
          <ButtonActionElement callback={handleShare} size={20} icon={'share'}/>
        </div>
      </div>
      <div className={BaseStyle.contentContainer}>
        { images &&
          <>
            <MediaPlayer pictures={images} />
          </>
        }
      </div>
    </>
  );
};

export default Record;

import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import NotificationElementStyle from '../assets/css/NotificationElement.module.css';
import dayjs from "dayjs";
import {useDesktop} from "../provider/DesktopContext";
import {useAppContext} from "../provider/appProvider";
import api from "../services/api";

const NotificationElement = (props) => {
  const { id, type, title, description, callback, calledBy, readed, seen, createdAt, thumbnail, fullDate = false } = props;

  const navigate = useNavigate();

  const {notifications, setNotifications} = useAppContext();

  const isDesktop = useDesktop();

  useEffect(() => {
    if (!seen) {
      const copyNotifications = notifications.map((notification) => {
        if (notification.id === id) {
          return { ...notification, seen_at: new Date() };
        }
        return notification;
      });
      setNotifications(copyNotifications);
      api.patch(`/notifications/${id}/see`);
    }

    return () => {
      console.log(`NotificationElement démonté avec id: ${id}`);
    };
  }, []);

  const handleCallCallback = (event) => {
    if (!readed) {
      const copyNotifications = notifications.map((notification) => {
        if (notification.id === id) {
          return { ...notification, readed_at: new Date()};
        }
        return notification;
      });
      setNotifications(copyNotifications);
      api.patch(`/notifications/${id}/read`);
    }

    if (callback) {
      navigate(callback, { replace: true });
    }
  }

  return (
    <div className={NotificationElementStyle.element} onClick={handleCallCallback}>
      {isDesktop &&
        <div className={NotificationElementStyle.icon}>
          <img src={`https://cdn.codnum.fr/watch4safe/icons/toast/${type}.svg`} alt={type} height={24} width={24}/>
        </div>
      }
      <div className={NotificationElementStyle.content}>
        <div className={NotificationElementStyle.left}>
          {!isDesktop &&
            <div className={NotificationElementStyle.iconMobile}>
              <img src={`https://cdn.codnum.fr/watch4safe/icons/toast/${type}.svg`} alt={type} height={24} width={24}/>
            </div>
          }
          <div className={NotificationElementStyle.text}>
            <span className={NotificationElementStyle.title}>{title}</span>
            <span
              className={NotificationElementStyle.description}>{description}{(description && calledBy) && '・'}{calledBy}</span>
            <span
              className={NotificationElementStyle.description_02}>{dayjs(createdAt).format(fullDate ? '[Le] DD MMMM YYYY [à] HH[h]mm [et] ss[s]' : '[À] HH[h]mm [et] ss[s]')}</span>
          </div>
        </div>

        <div className={NotificationElementStyle.right}>
          {thumbnail == null &&
            <>
              {/*<LoaderIcon className={'loader'} height={40} width={40}></LoaderIcon>*/}
            </>
          }
          {thumbnail != null &&
            <>
              <img loading="lazy" className={NotificationElementStyle.thumbnail} src={thumbnail}
                   alt="Moment de la capture"
                   onError={(e) => { e.target.style.display = 'none'; }}/>
            </>
          }

        </div>
      </div>
      {!readed &&
        <div className={NotificationElementStyle.notReaded}></div>
      }
    </div>
  );
};

export default NotificationElement;

import React, {useEffect, useState} from "react";

import ShortcutElementStyle from './ShortcutElement.module.css';
import '../../index.css'
import api from "../../services/api";
import { ReactComponent as LoaderIcon } from '../../assets/images/loader.svg';
import {useTheme} from "../../provider/themeProvider";

const ShortcutElement = (props) => {
  const { name, icon, iconActive, status, statusActive, infosURLTag, URLTag, activeURTag, type = 0 } = props;
  const [isActive, setActive] = useState(null);

  const { darkMode } = useTheme();

  const verifyIsActive = async (urlInfosTag) => {
    try {
      const response = await api.get(`/short-urls/${urlInfosTag}/execute`);
      setActive(Boolean(response.data));
    } catch (error) {
      console.log((error.response && error.response.data) || error.message || error.toString());
    }
  };

  useEffect(() => {
    if (infosURLTag) {
      const intervalId = setInterval(() => {
        verifyIsActive(infosURLTag);
      }, 5000);
      verifyIsActive(infosURLTag);

      return () => {
        clearInterval(intervalId);
        console.log("ShortcutElement has been unmounted from the DOM.");
      };
    } else {
      setActive(true);
    }
  }, [infosURLTag]);

  const handleCLickShortcut = async (event) => {
    if(!URLTag || isActive === null) {
      return;
    }

    if (URLTag && !activeURTag) {
      await api.get(`/short-urls/${URLTag}/execute`);
      return;
    }

    if (isActive) {
      await api.get(`/short-urls/${activeURTag}/execute`);
    } else {
      await api.get(`/short-urls/${URLTag}/execute`);
    }
    verifyIsActive(infosURLTag);
  }

  return (
    <>
      { type === 0 && (
        <div className={ShortcutElementStyle.element} onClick={handleCLickShortcut}>
          {isActive === null && (
            <>
              <LoaderIcon className={'loader'} alt={name} height={20} width={20}/>
              <div className={ShortcutElementStyle.nameContainer}>
                <span className={ShortcutElementStyle.name}>Chargement...</span>
              </div>
            </>
          )}

          {isActive !== null && (
            <>
              <img
                src={isActive ? (iconActive ?? 'https://vault.watch4safe.fr/public/assets/icons/process.svg') : (icon ?? 'https://vault.watch4safe.fr/public/assets/icons/process.svg')}
                alt={name} height={20}
                width={20}/>
              <div className={ShortcutElementStyle.nameContainer}>
                <span className={ShortcutElementStyle.name}>{name}</span>
              </div>
            </>
          )}
        </div>
      )}

      {type === 1 && (
        <div className={ShortcutElementStyle.element02} onClick={handleCLickShortcut}>
          {isActive === null && (
            <>
              <LoaderIcon className={'loader'} alt={name} height={40} width={40}/>
              <span className={ShortcutElementStyle.name02}>{'Chargement...'}</span>
              <span className={ShortcutElementStyle.status}>{'Inconnu'}</span>
            </>
          )}

          {isActive !== null && (
            <>
              <img
                src={isActive ? (iconActive ?? 'https://vault.watch4safe.fr/public/assets/icons/process.svg') : (icon ?? 'https://vault.watch4safe.fr/public/assets/icons/process.svg')}
                alt={name} height={40} width={40}/>
              <span className={ShortcutElementStyle.name02}>{name}</span>
              <span className={ShortcutElementStyle.status}>{isActive ? (statusActive ?? 'Inconnu') : (status ?? 'Inconnu')}</span>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ShortcutElement;

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { DashboardRoute } from "./DashboardRoute";
import {NotAuthenticatedRoute} from './NotAuthenticatedRoute';
import { AuthenticationRoute } from "./AuthenticationRoute";

import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Dashboard from '../pages/Dashboard'
import Cameras from "../pages/Cameras";
import Camera from "../pages/Camera";
import Notifications from "../pages/Notifications";
import Sequences from "../pages/Sequences";
import Record from "../pages/Record";
import Timelapse from "../pages/Timelapse";
import PublicTimelapse from "../pages/PublicTimelapse";
import {PopupProvider} from "../services/PopupContext";

const Routes = () => {
  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: "logout",
      element: <Logout/>,
    },
    {
      path: "/sharing/sequences/:sequenceType/",
      children: [
        {
          children: [
            {
              path: ":id",
              element: <PopupProvider><PublicTimelapse/></ PopupProvider>,
            }
          ]
        }
      ],
    },
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          element: <DashboardRoute />,
          children: [
            {
              path: "",
              element: <Dashboard />,
            },
            {
              path: "notifications",
              element: <Notifications />,
            },
            {
              path: "cameras",
              children: [
                {
                  path: "",
                  element: <Cameras />,
                },
                {
                  path: ":id",
                  element: <Camera />,
                },
              ]
            },
            {
              path: "sequences",
              element: <Sequences />,
            },
            {
              path: "records",
              children: [
                {
                  path: ":id",
                  element: <Record />,
                },
              ]
            },
            {
              path: "timelapses",
              children: [
                {
                  path: ":id",
                  element: <Timelapse />,
                },
              ]
            },
            {
              path: "system",
              children: [
                {
                  path: "",
                  element: <span>System</span>,
                },
                {
                  path: "settings",
                  element: <span>settings</span>,
                },
                {
                  path: "subscription",
                  element: <span>Abonnement</span>,
                },
                {
                  path: "users",
                  element: <span>Utilisateurs</span>,
                },
              ]
            },
            {
              path: "account",
              children: [
                {
                  path: "",
                  element: <div>Mon compte</div>,
                },
              ]
            },
          ]
        }
      ],
    },
    {
      path: "/user",
      element: <ProtectedRoute />,
      children: [
        {
          path: "",
          element: <div>Utilisateur</div>,
        },
        {
          path: "sub",
          element: <div>Abonnement</div>,
        },
      ]
    },
    {
      path: "/account",
      element: <ProtectedRoute />,
      children: [
        {
          path: "",
          element: <div>Utilisateur</div>,
        },
      ]
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: "/auth",
      element: <NotAuthenticatedRoute />,
      children: [
        {
          element: <AuthenticationRoute />,
          children: [
            {
              path: "login",
              element: <Login/>,
            }
          ]
        }

      ],
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...routesForNotAuthenticatedOnly,
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;

import BaseStyle from '../assets/css/Dashboard.module.css';
import NotificationsDateElement from "../components/NotificationsDateElement";
import React, {useEffect, useState, useCallback} from "react";
import {useTheme} from "../provider/themeProvider";
import {useAppContext} from "../provider/appProvider";
import HomeStyle from "../assets/css/Home.module.css";
import {useDesktop} from "../provider/DesktopContext";

const Notifications = () => {
  const [visibleCount, setVisibleCount] = useState(5);
  const { darkMode } = useTheme();
  const { notifications, groupedNotifications} = useAppContext();
  const isDesktop = useDesktop();

  const handleScroll = useCallback(() => {
    const bottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 50;
    if (bottom && visibleCount < groupedNotifications().length) {
      setVisibleCount(prevCount => prevCount + 1);
    }
  }, [visibleCount, groupedNotifications().length]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      {isDesktop &&
        <>
          <div className={BaseStyle.header}>
            <div className={BaseStyle.left}>
              <div className={BaseStyle.iconContainer}>
                <img src={`https://cdn.codnum.fr/watch4safe/assets/images/notification_${darkMode ? 'black' : 'white'}.svg`} alt="" height="20" width="20"/>
              </div>
              <div className={BaseStyle.description}>
                <span className={BaseStyle.title}>Notifications</span>
                <span className={BaseStyle.subTitle}>Toutes vos notifications</span>
              </div>
            </div>
            <div className={BaseStyle.right}>
            </div>
          </div>
        </>
      }
      {!isDesktop &&
        <span className={HomeStyle.title}>Notifications</span>
      }
      <div className={BaseStyle.contentContainerY}>
        {groupedNotifications().slice(0, visibleCount).map((date, index) => {
          return (
            <NotificationsDateElement key={index} date={new Date(date.date)} notifications={date.notifications} />
          );
        })}
      </div>
    </>
  );
};

export default Notifications;

import React, {useEffect, useState} from "react";

import { useTheme } from '../../provider/themeProvider';

import PopupDownNewSequenceStyle from './PopupDownNewSequence.module.css';
import {useAppContext} from "../../provider/appProvider";

import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import dayjs from "dayjs";
import CameraElement from "../CameraElement";
import api from "../../services/api";
import {usePopup} from "../../services/PopupContext";
import PopupDownNewSequenceLoading from "./PopupDownNewSequenceLoading";
import {useDesktop} from "../../provider/DesktopContext";

const PopupDownNewSequence = (props) => {
  const { cameraSelect, index } = props;

  const [cameraSelected, setCameraSelected] = useState(cameraSelect);
  const [dateStart, setDateStart] = useState(dayjs(new Date(new Date().setHours(0,0,0,0))));
  const [dateEnd, setDateEnd] = useState(dayjs(new Date()));

  const { darkMode } = useTheme();
  const { cameras } = useAppContext();
  const isDesktop = useDesktop();

  const { showPopup, hidePopup } = usePopup();

  const css = {
    '.MuiPickersInputBase-root': {
      backgroundColor: darkMode ? '#303030' : '#F3F3F3',
      border: 0,
      color: darkMode ? '#FFFFFF' : '#0A0908',
      padding: '0 6px',
    },
    'fieldset': {
      border: '0 !important',
    },
    '.MuiSvgIcon-root': {
      color: darkMode ? '#FFFFFF' : '#0A0908',
    },
    '.MuiPickersSectionList-root': {
      padding: '6px 0px',
    }
  }

  const handleCreateSequence = async (event) => {
    try {
      const response = await api.post(`/timelapse`, {
        camera_id: cameraSelected,
        start_at: (dateStart.unix() * 1000),
        end_at: (dateEnd.unix() * 1000),
      });

      hidePopup(index);
      showPopup(PopupDownNewSequenceLoading, {timelapseID: response.data.id});
    } catch (error) {
      console.log((error.response && error.response.data) || error.message || error.toString());
    }
  }

  useEffect(() => {
    console.log('salut');
  }, []);

  return (
    <div className={PopupDownNewSequenceStyle.container}>
      <span className={PopupDownNewSequenceStyle.title}>Nouvelle séquence</span>
      <div className={PopupDownNewSequenceStyle.cameras}>
        <span className={PopupDownNewSequenceStyle.text}>À partir de la caméra {cameraSelected ? `: ${cameras.find((camera) => camera.id === cameraSelected).name}` : ''}</span>
        <div className={PopupDownNewSequenceStyle.camerasSelector}>
          {cameras.map((camera, index) => {
            return (
              <div key={index} onClick={() => setCameraSelected(camera.id)}>
                <CameraElement name={camera.name} link={camera.stream_link ?? camera.signedLink} gridSize={99} id={camera.id} type={1} selected={cameraSelected === camera.id}></CameraElement>
              </div>
            );
          })}
        </div>
      </div>
      <div className={PopupDownNewSequenceStyle.dates}>
        <span className={PopupDownNewSequenceStyle.datesText}>Du</span>
        <div className={PopupDownNewSequenceStyle.datesSelector}>
          { isDesktop &&
            <DesktopDateTimePicker
              onChange={(newValue, context) => {
                if (context.validationError == null) {
                  setDateStart(newValue);
                }
              }}
              className={PopupDownNewSequenceStyle.dateTimePicker}
              ampm={false}
              format="DD/MM/YYYY à HH:mm"
              minDateTime={dayjs(new Date(new Date().setMonth(new Date().getMonth()-1)))}
              maxDateTime={dateEnd.add(-1, 'minute')}
              timezone="Europe/Paris"
              defaultValue={dayjs(new Date()).add(-1, 'minute')}
              timeSteps={{ hours: 1, minutes: 1}}
              skipDisabled
              disableFuture
              sx={css}
              enableAccessibleFieldDOMStructure={true}
            />
          }

          { !isDesktop &&
            <MobileDateTimePicker
              onChange={(newValue, context) => {
                if (context.validationError == null) {
                  setDateStart(newValue);
                }
              }}
              className={PopupDownNewSequenceStyle.dateTimePicker}
              ampm={false}
              format="DD/MM/YYYY à HH:mm"
              minDateTime={dayjs(new Date(new Date().setMonth(new Date().getMonth()-1)))}
              maxDateTime={dateEnd.add(-1, 'minute')}
              timezone="Europe/Paris"
              defaultValue={dayjs(new Date()).add(-1, 'minute')}
              timeSteps={{ hours: 1, minutes: 1}}
              skipDisabled
              disableFuture
              sx={css}
              enableAccessibleFieldDOMStructure={true}
            />
          }
        </div>
        <span className={PopupDownNewSequenceStyle.datesText}>au</span>
        <div className={PopupDownNewSequenceStyle.datesSelector}>
          { isDesktop &&
            <DesktopDateTimePicker
              onChange={(newValue, context) => {
                if (context.validationError == null) {
                  setDateEnd(newValue);
                }
              }}
              className={PopupDownNewSequenceStyle.dateTimePicker}
              ampm={false}
              format="DD/MM/YYYY à HH:mm"
              minDateTime={dateStart.add(1, 'minute')}
              timezone="Europe/Paris"
              defaultValue={dayjs(new Date())}
              timeSteps={{ hours: 1, minutes: 1}}
              skipDisabled
              disableFuture
              sx={css}
              enableAccessibleFieldDOMStructure={true}
            />
          }

          { !isDesktop &&
            <MobileDateTimePicker
              onChange={(newValue, context) => {
                if (context.validationError == null) {
                  setDateEnd(newValue);
                }
              }}
              className={PopupDownNewSequenceStyle.dateTimePicker}
              ampm={false}
              format="DD/MM/YYYY à HH:mm"
              minDateTime={dateStart.add(1, 'minute')}
              timezone="Europe/Paris"
              defaultValue={dayjs(new Date())}
              timeSteps={{ hours: 1, minutes: 1}}
              skipDisabled
              disableFuture
              sx={css}
              enableAccessibleFieldDOMStructure={true}
            />
          }
        </div>
      </div>
      <div className={PopupDownNewSequenceStyle.generateContainer}>
        <input value={'Générer'} type={"button"} className={'btn-01'} disabled={!(cameraSelected && dateStart && dateEnd)} onClick={handleCreateSequence} />
      </div>
    </div>
  );
};

export default PopupDownNewSequence;

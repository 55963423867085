import axios from "axios";
import TokenService from "./token.service";
import {getLocalAccessToken, removeUser, getLocalSystemToken, removeSystem} from "../provider/authProvider";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();
    if (token) {
      config.headers["x-access-token"] = token;
    }

    const systemToken = getLocalSystemToken();
    if (systemToken) {
      config.headers["x-system-token"] = systemToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 498 && !originalConfig._retry) {
        originalConfig._retry = true;

        console.log('Access Token was expired')

        try {
          const rs = await instance.post("/auth/refresh-token", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });

          const { accessToken } = rs.data;
          TokenService.updateLocalAccessToken(accessToken);

          return instance(originalConfig);
        } catch (_error) {
          await removeUser();
          await removeSystem();
          return Promise.reject(_error);
        }
      } else {
        originalConfig._retry = false;
      }
    }
    return Promise.reject(err);
  }
);

export default instance;

import React, { createContext, useState, useContext, useEffect } from 'react';
import api from "../services/api";
import {getSystemID} from "./authProvider";

const AppStateContext = createContext();

export const useAppContext = () => useContext(AppStateContext);

export const AppProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [systemsAvailable, setAvailableSystems] = useState([]);
  const [system, setSystem] = useState(null);
  const [user, setUser] = useState(null);
  const [cameras, setCameras] = useState([]);
  const [shortcuts, setShortcuts] = useState([]);
  const [timelapses, setTimelapses] = useState([]);


  const countNotification = ()  => {
    return notifications.filter(notification => notification.seen_at === null).length
  }

  const groupedNotifications = () => {
    return notifications.reduce((acc, notification) => {
      const date = notification.createdAt.split("T")[0];

      const existingGroup = acc.find(group => group.date === date);

      if (existingGroup) {
        existingGroup.notifications.push(notification);
      } else {
        acc.push({ date, notifications: [notification] });
      }

      return acc;
    }, []);
  }

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await api.get('/notifications');

        const notifications = response.data;
        console.log(notifications);

        const sorted = notifications.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setNotifications(sorted);
      } catch (error) {
        console.error("Failed to fetch notifications", error);
        setNotifications([]);
      }
    };

    fetchNotifications();
  }, []);

  useEffect(() => {
    const getShortCuts = async () => {
      try {
        const response = await api.get(`/shortcuts`);
        setShortcuts(response.data);
      } catch (error) {
        console.error(error);
        setShortcuts([]);
      }
    };

    getShortCuts();
  }, []);

  useEffect(() => {
    console.log('getUser');
    const getUser = async () => {
      console.log('getUser');
      try {
        const response = await api.get(`/users/me`);
        setUser(response.data);
      } catch (error) {
        console.log('ERROR GET USER');
        console.error(error);
        setUser(null);
      }
    };

    getUser();
  }, []);

  useEffect(() => {
    const getTimelapses = async () => {
      try {
        const response = await api.get(`/timelapses`);
        console.log('Timelapses', response.data)
        setTimelapses(response.data);
      } catch (error) {
        console.error(error);
        setTimelapses([]);
      }
    };

    getTimelapses();
  }, []);

  useEffect(() => {
    const getCurrentSystem = async () => {
      try {
        const systemID = getSystemID();
        const response = await api.get(`/systems/${systemID}`);
        console.log('Système', response.data)
        setSystem(response.data);
      } catch (error) {
        console.error(error);
        setSystem(null);
      }
    };

    getCurrentSystem();
  }, []);

  useEffect(() => {
    const fetchCameras = async () => {
      try {
        const response = await api.get('/cameras');
        setCameras(response.data);
      } catch (error) {
        console.error("Failed to fetch cameras", error);
        setCameras([]);
      }
    };

    fetchCameras();
  }, []);

  useEffect(() => {
    const fetchSystems = async () => {
      try {
        const response = await api.get('/systems');
        const result = [];

        for(const theSystem of response.data) {
          result.push({
            image: 'https://cdn.codnum.fr/watch4safe/assets/images/favicon.svg',
            icon: false,
            title: theSystem.name,
            link: ``,
            id : theSystem.id,
          });
        }
        setAvailableSystems(result);
      } catch (error) {
        setAvailableSystems([]);
      }
    };

    fetchSystems();
  }, []);

  return (
    <AppStateContext.Provider value={{countNotification, groupedNotifications, systemsAvailable, cameras, shortcuts, notifications, setNotifications, system, setSystem, timelapses, setTimelapses, user, setUser}}>
      {children}
    </AppStateContext.Provider>
  );
};

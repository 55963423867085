import BaseStyle from '../assets/css/Dashboard.module.css';
import TimelapsesStyle from '../assets/css/Timelapses.module.css';

import {useTheme} from "../provider/themeProvider";
import React from "react";
import PopupDownNewSequence from "../components/PopupDown/PopupDownNewSequence";
import {usePopup} from "../services/PopupContext";
import {useAppContext} from "../provider/appProvider";
import dayjs from "dayjs";
import api from "../services/api";
import {useNavigate} from 'react-router-dom';
import {useDesktop} from "../provider/DesktopContext";

const Sequences = () => {
  const { darkMode } = useTheme();
  const { showPopup } = usePopup();
  const { timelapses, setTimelapses } = useAppContext();
  const isDesktop = useDesktop();
  const navigate = useNavigate();

  const handleClickFavorite = async (index, event) => {
    event.stopPropagation();
    try {
      const timelapseID = timelapses[index].id;
      const response = await api.put(`/client/timelapse/${timelapseID}`, {action: 'favorite'});
      if (response.data.success) {
        const updatedTimelapses = [...timelapses];
        updatedTimelapses[index].favorite = response.data.data.favorite;
        setTimelapses(updatedTimelapses);
      }
    } catch (error) {
      console.log('error');
    }
  };

  const handleOpenTimelapse = (timelapse) => {
    navigate(`/timelapses/${timelapse.id}`, { replace: true });
  };

  return (
    <>
      {isDesktop &&
        <div className={BaseStyle.header}>
          <div className={BaseStyle.left}>
            <div className={BaseStyle.iconContainer}>
              <img src={`https://cdn.codnum.fr/watch4safe/assets/images/folder_${darkMode ? 'black' : 'white'}.svg`}
                   alt=""
                   height="20" width="20"/>
            </div>
            <div className={BaseStyle.description}>
              <span className={BaseStyle.title}>Séquences</span>
              <span className={BaseStyle.subTitle}>Toutes vos restitutions vidéo</span>
            </div>
          </div>
          <div className={BaseStyle.right}>
            <div
              className={BaseStyle.btnAction}
              onClick={(event) => showPopup(PopupDownNewSequence)}
            >
              <img src={`https://cdn.codnum.fr/watch4safe/assets/images/plus_${darkMode ? 'black' : 'white'}.svg`}
                   alt={''}
                   height={14} width={14}></img>
            </div>
          </div>
        </div>
      }
      <div className={TimelapsesStyle.contentContainer}>
        {timelapses && timelapses.map((timelapse, index) => {
          return (
            <div key={index} className={TimelapsesStyle.timelapse} onClick={() => handleOpenTimelapse(timelapse)}>
              <div className={TimelapsesStyle.left}>
                <span className={TimelapsesStyle.title}>{timelapse.title}</span>
                <div className={TimelapsesStyle.leftCenter}>
                  <span
                    className={TimelapsesStyle.time}>Du <b>{dayjs(timelapse.start_at).format('DD MMMM YYYY [à] HH[h]mm')}</b> au <b>{dayjs(timelapse.end_at).format('DD MMMM YYYY [à] HH[h]mm')}</b></span>
                  <span className={TimelapsesStyle.camera}>{timelapse.camera.name}</span>
                </div>
                {/*<span*/}
                {/*  className={TimelapsesStyle.expire}>Expire dans {dayjs(timelapse.created_at).add(timelapse.system.purge_day_server, 'day').diff(dayjs(), 'day')} jours</span>*/}
              </div>
              <div className={TimelapsesStyle.right}>
                <div className={TimelapsesStyle.favorite} onClick={console.log('cc')/* (event) => handleClickFavorite(index, event) */}>
                  {/*<img src={`https://cdn.codnum.fr/watch4safe/assets/images/star_${timelapse.favorite ? 'selected_' : ''}${darkMode ? 'black' : 'white'}.svg`}*/}
                  {/*     alt={''}*/}
                  {/*     height={22} width={22} />*/}
                </div>
                <span className={TimelapsesStyle.created}>Créé le {dayjs(timelapse.createdAt).format('DD MMMM YYYY [à] HH[h]mm')}</span>
              </div>
            </div>
          )
        })}
      </div>
    </>
  );
};

export default Sequences;

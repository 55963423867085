import React from "react";

import PopupDownShortcutStyle from './PopupDownShortcut.module.css';
import {useAppContext} from "../../provider/appProvider";
import ShortcutElement from "../Shortcut/ShortcutElement";

const PopupDownShortcut = (props) => {
  const { shortcuts } = useAppContext();

  return (
    <div className={PopupDownShortcutStyle.container}>
      <span className={PopupDownShortcutStyle.title}>Raccourcis</span>
      <div className={PopupDownShortcutStyle.content}>
        {shortcuts && shortcuts.map((shortcut, index) => {
          return(
            <ShortcutElement
              key={index}
              name={shortcut.name}
              icon={shortcut.defaultShortcuts ? shortcut.defaultShortcuts.link : null}
              iconActive={shortcut.activeShortcuts ? shortcut.activeShortcuts.link : null}
              status={shortcut.title_status_default}
              statusActive={shortcut.title_status_active}
              infosURLTag={shortcut.infos_shorts_urls_id}
              URLTag={shortcut.default_shorts_urls_id}
              activeURTag={shortcut.active_shorts_urls_id}
              type={1}
            />
          )
        })}
      </div>
    </div>
  );
};

export default PopupDownShortcut;

import React from "react";

/**
 * Styles
 */
import ShortcutElementStyle from './ShortcutElement.module.css';
import ShortcutStyle from './Shortcut.module.css';

import ShortcutElement from "./ShortcutElement";
import {useTheme} from "../../provider/themeProvider";
import {useAppContext} from "../../provider/appProvider";
import PopupDownAlarm from "../PopupDown/PopupDownAlarm";
import {usePopup} from "../../services/PopupContext";
import popupDownShortcut from "../PopupDown/PopupDownShortcut";

const Shortcut = () => {
  const { darkMode } = useTheme();
  const { shortcuts, system } = useAppContext();
  const { showPopup } = usePopup();


  return (
    <div className={ShortcutStyle.shortcut}>
      <div className={ShortcutStyle.top}>
        <div className={ShortcutElementStyle.element} onClick={(event) => showPopup(PopupDownAlarm)}>
          <img
            src={`https://cdn.codnum.fr/watch4safe/assets/images/favicon${system && system.alarm_enabled ? '_active' : ''}_${darkMode ? 'black' : 'white'}.svg`}
            alt={'Alarme'} height={20}
            width={20}/>
            <div className={ShortcutElementStyle.nameContainer}>
              <span className={ShortcutElementStyle.name}>Système de détection - {system && (system.alarm_enabled ? 'activé' : 'désactivé')} {system && system.alarm_enabled && system.activeAlarmLevel && (('- ') + system.activeAlarmLevel.name)}</span>
            </div>
        </div>
      </div>

      <div className={ShortcutStyle.center}>
        {shortcuts && shortcuts.map((shortcut, index) => {
          return (
            <ShortcutElement
              key={index}
              name={shortcut.name}
              icon={shortcut.defaultShortcuts ? shortcut.defaultShortcuts.link : null}
              iconActive={shortcut.activeShortcuts ? shortcut.activeShortcuts.link : null}
              status={shortcut.title_status_default}
              statusActive={shortcut.title_status_active}
              infosURLTag={shortcut.infos_shorts_urls_id}
              URLTag={shortcut.default_shorts_urls_id}
              activeURTag={shortcut.active_shorts_urls_id}
            />
          );
        })}
      </div>

      <div className={ShortcutStyle.bottom}>
        <div className={ShortcutElementStyle.element} onClick={(event) => showPopup(popupDownShortcut)}>
          <img
            src={`https://cdn.codnum.fr/watch4safe/assets/images/stack_${darkMode ? 'black' : 'white'}.svg`}
            alt={'Raccourcis'} height={20}
            width={20}/>
          <div className={ShortcutElementStyle.nameContainer}>
            <span className={ShortcutElementStyle.name}>Mes raccourcis</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shortcut;

import React from "react";

import { useTheme } from '../../provider/themeProvider';

import PopupDownAlarmStyle from './PopupDownAlarm.module.css';
import {useAppContext} from "../../provider/appProvider";
import api from "../../services/api";

const PopupDownAlarm = (props) => {

  const { system, setSystem } = useAppContext();
  const { darkMode } = useTheme();

  const enable = system.alarm_enabled;

  const handleClickAlarmActivated = async (enabled) => {
    if (enabled === system.alarm_enabled) {
      return;
    }
    try {
      const response = await api.patch(`/systems/${system.id}/alarm/enable`, {enable: enabled});
      setSystem(response.data);
    } catch (error) {
      console.log('error');
    }
  };

  const handleClickAlarmLevel = async (level) => {
    if (!system.alarm_enabled) {
      return;
    }

    try {
      const response = await api.patch(`/systems/${system.id}/alarm/level`, {levelID: level});
      setSystem(response.data);
    } catch (error) {
      console.log('error');
    }
  };

  return (
    <div className={PopupDownAlarmStyle.container}>
      <span className={PopupDownAlarmStyle.title}>Système de détection</span>
      <div className={PopupDownAlarmStyle.content}>
        <div className={PopupDownAlarmStyle.activated}>
          <div className={`${PopupDownAlarmStyle.element} ${!enable ? PopupDownAlarmStyle.selected : ''}`} onClick={() => handleClickAlarmActivated(false)}>
            <div className={PopupDownAlarmStyle.iconContainer}>
              <img className={PopupDownAlarmStyle.icon}
                   src={`https://cdn.codnum.fr/watch4safe/assets/images/alarm_unlocked_${(enable ? darkMode : !darkMode) ? 'black' : 'white'}.svg`}
                   alt={'Désactivé'} width={22} height={22}/>
            </div>
            <span className={PopupDownAlarmStyle.name}>Désactivé</span>
          </div>
          <div className={`${PopupDownAlarmStyle.element} ${enable ? PopupDownAlarmStyle.selected : ''}`} onClick={() => handleClickAlarmActivated(true)}>
            <div className={PopupDownAlarmStyle.iconContainer}>
              <img className={PopupDownAlarmStyle.icon}
                   src={`https://cdn.codnum.fr/watch4safe/assets/images/alarm_locked_${(enable ? !darkMode : darkMode) ? 'black' : 'white'}.svg`}
                   alt={'Activé'} width={22} height={22}/>
            </div>
            <span className={PopupDownAlarmStyle.name}>Activé</span>
          </div>
        </div>

        <span>Niveau d’alerte</span>

        <div className={`${PopupDownAlarmStyle.level} ${!system.alarm_enabled ? PopupDownAlarmStyle.disabled : ''}`}>
          {system.alarmLevels.map((alarm, index) => {
            const selected = alarm.id === system.alarms_levels_id_active;
            return (
              <div
                className={`${PopupDownAlarmStyle.element} ${selected ? PopupDownAlarmStyle.selected : ''}`}
                onClick={() => handleClickAlarmLevel(alarm.id)}>
                <div className={PopupDownAlarmStyle.iconContainer}>
                  <img className={PopupDownAlarmStyle.icon}
                       src={!selected ? alarm.selectedIcon.link : alarm.icon.link}
                       alt={'Désactivé'} width={22} height={22}/>
                </div>
                <span className={PopupDownAlarmStyle.name}>{alarm.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PopupDownAlarm;
